import React from "react"
import {graphql, Link} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import Div100vh from "react-div-100vh";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import localize from "../components/localize";
import {localeLink} from "../util";

export const query = graphql`
    {
        allSanitySettings{
            edges {
                node {
                    telefon
                    sociallinks {
                        value
                        type
                        title
                    }
                    maintenance{
                        maintenancewebsite
                        maintenanceactive
                        maintenance_seo_description{
                            _type
                            en
                            de
                        }
                        maintenanceimage{
                            asset{
                                gatsbyImageData
                            }
                        }
                        maintenance_seo_title{
                            _type
                            en
                            de
                        }
                        maintenancetitle{
                            _type
                            en
                            de
                        }
                        maintenancebutton {
                            value
                            title
                        }
                        _rawMaintenancetext
                    }
                    email
                    address_street
                    address_plz
                    address_name
                    address_nr
                    address_ort
                    error {
                        title{
                            _type
                            en
                            de
                        }
                        _rawText
                        seo_title{
                            _type
                            en
                            de
                        }
                        button{
                            title
                            fixedpage
                        }
                    }
                }
            }

        }
        allSanityStartseite {
            edges {
                node {
                    image{
                        asset{
                            gatsbyImageData
                        }
                    }
                    aboutheadline{
                        _type
                        en
                        de
                    }
                    photoheadline{
                        _type
                        en
                        de
                    }
                    videoheadline{
                        _type
                        en
                        de
                    }
                    factsheadline{
                        _type
                        en
                        de
                    }
                }
            }
        }
        site {
            siteMetadata {
                lang{
                    _type
                    en
                    de
                }
                work{
                    _type
                    en
                    de
                }
                aboutme{
                    _type
                    en
                    de
                }
                contact{
                    _type
                    en
                    de
                }
                homepage{
                    _type
                    en
                    de
                }
            }
        }
        allSanityDatenschutz{
            edges {
                node {
                    title{
                        _type
                        en
                        de
                    }
                }
            }
        }
        allSanityImpressum{
            edges {
                node {
                    title{
                        _type
                        en
                        de
                    }
                }
            }
        }
    }`;

const NotFoundPage = ({data, location}) => {

    const page = data.allSanitySettings.edges[0].node;
    const metadata = data.site.siteMetadata;
    const startseite = data.allSanityStartseite.edges[0].node;

    return (
        <Layout settings={page} location={location} metadata={metadata} servicesheadline={startseite.servicesheadline} videoheadline={startseite.videoheadline} photoheadline={startseite.photoheadline} aboutheadline={startseite.aboutheadline} lang={metadata.lang} animated={false} privacy={data.allSanityDatenschutz.edges[0].node.title} imprint={data.allSanityImpressum.edges[0].node.title}>
            <SEO title={"404"}/>
            <Div100vh className="landing__outer">
                <GatsbyImage className={"fullbackgrounddiv"} image={startseite.image.asset.gatsbyImageData} alt={"Capture the Feeling"}></GatsbyImage>
                <div className="landing__inner">
                    <div className="animation__outer animation__outer__headline">
                        <h1 className="color-light landing__headline">ERROR <span className="color-red">404</span></h1>
                    </div>
                    <div className="animation__outer hoverable">
                        <div className={"btn__landing__outer"}>
                            <Link to={localeLink("/", location)} className="btn__landing btn btn-red hover-moveup">{metadata.homepage}</Link>
                        </div>
                    </div>
                </div>
            </Div100vh>
        </Layout>)
}

export default localize(NotFoundPage)
